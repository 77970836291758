<template>
  <v-chart autoresize ref="distributionChart" :option="donutOptions" />
</template>
<script>
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  PieChart,
]);
export default {
  name: 'BloodSugarDistributionChart',
  components: {
    VChart,
  },
  props: {
    dataChart: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      donutOptions: {
        label: {
          formatter: '{b}  {d}%  ',
        },
        labelLine: {
          smooth: 0.2,
          length: 15,
          length2: 220,
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
    };
  },
  watch: {
    dataChart: {
      handler(val) {
        if (!val) return;
        this.generateChart();
      },
      deep: true,
    },
  },
  methods: {
    generateChart() {
      this.$refs.trendChart?.clear();
      let series = [
        {
          value:
            this.dataChart.veryLowCount > 0
              ? this.dataChart.veryLowCount
              : null,
          itemStyle: { color: this.dataChart.veryLowColor },
          name: 'Rất thấp',
        },
        {
          value: this.dataChart.lowCount > 0 ? this.dataChart.lowCount : null,
          itemStyle: { color: this.dataChart.lowColor },
          name: 'Thấp',
        },
        {
          value: this.dataChart.goodCount > 0 ? this.dataChart.goodCount : null,
          itemStyle: { color: this.dataChart.goodColor },
          name: 'Tốt',
        },
        {
          value: this.dataChart.highCount > 0 ? this.dataChart.highCount : null,
          itemStyle: { color: this.dataChart.highColor },
          name: 'Cao',
        },
        {
          value:
            this.dataChart.veryHighCount > 0
              ? this.dataChart.veryHighCount
              : null,
          itemStyle: { color: this.dataChart.veryHighColor },
          name: 'Rất cao',
        },
      ];
      this.donutOptions.series[0].data = series;
    },
  },
};
</script>
<style lang=""></style>
